.random-celeb {
    text-align: center;

    &__status {
        font-size: 1.6rem;
        position: relative;
    }

    &__title {
        font-size: 2rem;
        font-weight: 300;
    }

    &__name {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 2.3rem;
        white-space: pre;
        font-weight: 400;
    }

    &--picked &__name {
        font-size: 2.6rem;
        white-space: normal;

        &::after {
            content: "!";
        }
    }
}
