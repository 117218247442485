.gallery-list {
    margin: 3rem auto;
    padding: 0 2rem;
    max-width: 100rem;

    &__items {
        display: flex;
        // flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        font-size: 1.8rem;
        font-weight: 300;
        // margin-top: .5rem;
        margin-right: 2rem;
        margin-top: 1rem;
    }
}
