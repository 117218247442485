$ellipsis-animation-duration: 2s;

.ellipsis {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(100%);

    &__dot {
        &::after {
            content: ".";
        }

        &:nth-child(2) {
            animation: ellipsis-2 $ellipsis-animation-duration linear infinite;
        }
        &:nth-child(3) {
            animation: ellipsis-3 $ellipsis-animation-duration linear infinite;
        }
    }
}

@keyframes ellipsis-2 {
    0% {
        visibility: hidden;
    }
    33% {
        visibility: hidden;
    }
    66% {
        visibility: visible;
    }
}

@keyframes ellipsis-3 {
    0% {
        visibility: hidden;
    }
    66% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}
