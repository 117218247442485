.header {
    font-family: Helvetica, Arial;
    flex-shrink: 0;

    padding: 2rem 0;
    background-color: $header-color;
    box-shadow: $box-shadow-normal;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__title {
        text-align: center;
        font-size: 2.2rem;
    }

    &__reddit-logo {
        position: absolute;
        right: 2rem;
        width: 3rem;
        fill: white;
        padding: 0.5rem;
        box-sizing: content-box;
        display: none;

        @include responsive(300px) {
            display: block;
        }
    }
}
