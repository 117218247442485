.container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__content {
        margin: 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
    }
}
