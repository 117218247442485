.wait {
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;

    &__paragraph {
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }

    &__time {
        font-weight: 600;
        white-space: pre;
    }

    &__small-text {
        margin-top: 1rem;
        font-size: 1.6rem;
    }

    &__link {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
    }
}
