.rolls {
    margin-top: 3rem;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;

    &__line {
        margin-top: .5rem;
    }
    &__value {
        font-weight: 600;
    }
}
