.random-porn {
    text-align: center;

    &__title {
        font-size: 1.6rem;
        position: relative;
    }

    &__number {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 4rem;
        white-space: pre;
        font-weight: 400;
    }
}
