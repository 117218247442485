// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
    // Makes 1rem equal to 10px by default.
    font-size: baseFontSize(10px);

    @include responsive(600px) {
        font-size: baseFontSize(12px);
    }

    @include responsive(1000px) {
        font-size: baseFontSize(14px);
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    backface-visibility: hidden;
}

body {
    background-color: $background-color;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: $text-color;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
}

ul {
    list-style: none;
}

button {
    border: none;
    background-color: inherit;
    font: inherit;
    color: inherit;
}
