.copyright-link {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    margin: 2rem 0;

    &__link {
        cursor: pointer;
        text-decoration: underline;
    }
}
