$box-shadow-normal: 0rem 1rem 2rem rgba(0, 0, 0, 0.2);
$box-shadow-hover: 0rem 1.1rem 2rem rgba(0, 0, 0, 0.21);

.random-choices {
    &__title {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 3rem;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        
        @include responsive(800px) {
            flex-direction: row;
            > * {
                &:not(:first-child) {
                    margin-left: 2rem;
                }
            }
        }
    }
}

.random-button {
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    background-color: $button-color;
    margin-bottom: 2.5rem;

    padding: 2rem 4rem;
    border-radius: 6px;

    box-shadow: $box-shadow-normal;

    &:hover {
        cursor: pointer;
        box-shadow: $box-shadow-hover;
        transform: translateY(-2px);
        background-color: darken($button-color, 5%);
    }

    &:active {
        box-shadow: $box-shadow-normal;
        transform: translateY(1px);
    }
}
