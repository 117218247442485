.copyright-page {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    
    &__content {
        margin: auto;
        max-width: 38em;
        font-size: 18px;
        line-height: 1.7em;
        color: #403c3c;
        padding: 0em 2em 4em 2em;
        font-family: "Georgia", Georgia, Times, serif;
    }

    @media (max-width: 799px) {
        body {
            font-size: 14px !important;
        }
    }

    p {
        /* padding: 0em 0em 0em 0em; */
        margin-top: 1em;
    }

    h1 {
        /* text-align: center; */
        /* padding-bottom: 1em; */
    }

    h2 {
        padding-top: 2em;
        font-size: 1.5em;
    }

    h3 {
        padding-top: 0.5em;
        font-size: 1.1em;
    }

    h4 {
        /* padding-top: 0.1em; */
        font-size: 1em;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: Verdana, Geneva, sans-serif;
    }

    a {
        color: #2c8898;
        text-decoration: none;
    }
}
